function menuGlobalScope(Vue, $pnp) {
  var menuAdmMixin = {
    data: function () {
      return {
        isMobile: false,
      };
    },
    created: function () {
      this.onResize();
      $(window).resize(this.debounce(this.onResize.bind(this), 500));
    },
    methods: {
      closeOpenedItems: function (items) {
        items.forEach(
          function (item) {
            if (item.isOpened) {
              item.isOpened = false;
              if (item.children) this.closeOpenedItems(item.children);
            }
          }.bind(this),
        );
      },
      onResize: function () {
        this.isMobile = window.innerWidth < 1024;
      },
      debounce: function (func, wait, immediate) {
        var timeout;
        return function () {
          var context = this,
            args = arguments;
          var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      },
    },
  };

  $pnp.setup({
    headers: {
      Accept: "application/json; odata=verbose",
    },
  });

  require("../../mixins/translate");

  Vue.component("menu-global-multinivel", {
    mixins: [menuAdmMixin, translateMixin],
    props: {
      items: {
        type: Array,
        default: function () {
          return [];
        },
      },
      customClass: {
        type: String,
        default: "",
      },
      isChild: {
        type: Boolean,
        default: false,
      },
      isMenuMobile: {
        type: Boolean,
        default: false,
      },
      isFixed: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        list: {
          newUrl:
            _spPageContextInfo.webAbsoluteUrl + "/lists/MenuGlobal/new.aspx",
        },
      };
    },
    methods: {
      onItemMouseHover: function (item) {
        if (item.children.length) {
          if (!this.isMobile) {
            this.closeOpenedItems(this.items);
          }
          Vue.set(item, "isOpened", !item.isOpened);
        }
      },
      hasChildren: function (item) {
        return (item.children || []).length > 0;
      },
    },
    template: `
			<div v-if="!isMenuMobile" class="w-100">
				<ul :class="isChild ? customClass : 'navbar-nav'">
                    <template v-for="(item, index) in items" v-if="isFixed && item.TipoMenu == 'Principal'">
						<li :class="{'dropdown': hasChildren(item), 'dropdown-item' : isChild}" class="nav-item" v-if="">
							<a :href="item.URL ?  item.URL : 'javascript:'" class="nav-link"
								:class="{'dropdown-toggle': (item.children||[]).length}"
								:data-toggle="hasChildren(item) ? 'dropdown' : ''"
								:aria-haspopup="hasChildren(item) ? 'true' : ''"
								:aria-expanded="hasChildren(item) ? 'false' : ''">
								{{ isEnglish ? item.TItleEN : item.Title }}
							</a>
							<menu-global-multinivel v-if="hasChildren(item)" :items="item.children" custom-class="dropdown-menu" :is-child="true"/>
						</li>
					</template>
                    <template v-for="(item, index) in items" v-if="!isFixed">
						<li :class="{'dropdown': hasChildren(item), 'dropdown-item' : isChild}" class="nav-item" v-if="">
							<a :href="item.URL ?  item.URL : 'javascript:'" class="nav-link"
								:class="{'dropdown-toggle': (item.children||[]).length}"
								:data-toggle="hasChildren(item) ? 'dropdown' : ''"
								:aria-haspopup="hasChildren(item) ? 'true' : ''"
								:aria-expanded="hasChildren(item) ? 'false' : ''">
								{{ isEnglish ? item.TItleEN : item.Title }}
							</a>
							<menu-global-multinivel v-if="hasChildren(item)" :items="item.children" custom-class="dropdown-menu" :is-child="true"/>
						</li>
					</template>
				</ul>
			</div>
			<div v-else class="menu-dropdown-mobile pl-5">
				<div class="dropdown">
					<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuMobile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-bars text-dark"></i>
					</button>
					<div class="dropdown-menu text-center pt-4" aria-labelledby="dropdownMenuMobile">
						<button v-for="(item, index) in items" :class="{'dropdown': hasChildren(item), 'dropdown-item' : isChild}" class="dropdown-item font-weight-bold" type="button">
							<a :href="item.URL ?  item.URL : 'javascript:'" class="nav-link"
								:class="{'dropdown-toggle': (item.children||[]).length}"
								:data-toggle="hasChildren(item) ? 'dropdown' : ''"
								:aria-haspopup="hasChildren(item) ? 'true' : ''"
								:aria-expanded="hasChildren(item) ? 'false' : ''">
								{{ isEnglish ? item.TItleEN : item.Title }}
							</a>
						</button>
					</div>
		  		</div>
			</div>
			`,
  });

  Vue.component("menu-component", {
    mixins: [pnpMixin, menuAdmMixin, translateMixin],
    data: function () {
      return {
        items: [],
        isOpened: false,
        isUserAllowed: false,
        buscaHome: "",
        scrollPage: false,
        menuClose: `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/menu.png`,
        menuOpen: `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/close.png`,
        isLoaded: false,
      };
    },
    created: function () {
      this.getItems(
        _spPageContextInfo.siteServerRelativeUrl,
        _spPageContextInfo.siteAbsoluteUrl,
      )
        .then(this.parseLevels)
        .then(this.setItems)
        .then(this.closeMenu)
        .then(this.applyEvents)
        .then(() => (this.isLoaded = true));
    },
    mounted: function () {
      document.onclick = this.onClickOutside;
      window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
      onScroll(e) {
        var $document = $(document);

        if ($document.scrollTop() >= 3) {
          this.scrollPage = true;
        } else {
          this.scrollPage = false;
        }
      },
      debounce(func, wait, immediate) {
        var timeout;
        return function () {
          var context = this,
            args = arguments;
          var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      },
      applyEvents() {
        $(document).ready(function () {
          $("#fixedMenu").hide();
          $(".dropdown-menu").css("position", "absolute");
        });
      },
      onClickOutside: function () {
        this.closeOpenedItems(this.items);
        this.openMenu(false);
      },
      getItems: function (webRelative, webAbsolute) {
        var web = new $pnp.Web(webAbsolute);

        return web
          .getList(webRelative + "/lists/MenuGlobal")
          .items.top(500)
          .orderBy("Ordem")
          .get();
      },
      setItems: function (items) {
        this.items = items;
      },
      getChildren: function (items, parentId) {
        return items
          .filter(function (item) {
            return parentId === item.ItemPrincipalId;
          })
          .map(function (item) {
            item.levelId = item.ItemPrincipalId;
            return item;
          });
      },
      clone: function (obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
      },
      cloneArray: function (array) {
        return JSON.parse(JSON.stringify(array));
      },
      parseLevels: function (items) {
        var reduceLevels = function (levelAtual, curItem, index) {
          // é necessário clonar pois propriedades como levelId e children são alteradas nos loops
          // quando não clonamos, corremos o risco de perder a referencia de levelId por exemplo
          var allItems = this.cloneArray(items);

          if (
            curItem.levelId == curItem.ItemPrincipalId ||
            curItem.ID == curItem.levelId
          ) {
            curItem.levelId = curItem.ID;
            curItem.children = this.getChildren(allItems, curItem.ID).reduce(
              reduceLevels.bind(this),
              [],
            );
            levelAtual.push(curItem);
          }
          return levelAtual;
        };

        items = items.reduce(reduceLevels.bind(this), []);
        return items;
      },
      onMenuClick: function () {
        this.openMenu(!this.isOpened);
      },
      openMenu: function (open) {
        this.isOpened = open;
      },
      closeMenu() {
        $(document).mouseup(function (e) {
          var container = $("#navbarToggleExternalContent");

          // if the target of the click isn't the container nor a descendant of the container
          if (
            !container.is(e.target) &&
            container.has(e.target).length === 0 &&
            e.target.tagName != "BUTTON" &&
            e.target.tagName != "SPAN"
          ) {
            $(container).removeClass("show");
            $(".navbar-toggler").attr("aria-expanded", false);
          }
        });
      },
      searchHome() {
        window.location.href =
          _spPageContextInfo.siteAbsoluteUrl +
          `/busca#` +
          `keywords=${this.buscaHome}`;
      },
    },
    template: `
		<div id="menu" v-if="isLoaded">
            <nav class="navbar pl-0 pr-0" v-if="items.length" :class="isMobile ? 'position-relative' : ''">
				<a class="nav-brand wow fadeIn" href="/" :title="_spPageContextInfo.webTitle" data-wow-delay=".1s">
					<img :src="_spPageContextInfo.webLogoUrl" class="logo" :alt="_spPageContextInfo.webTitle"/>
				</a>
				
				<div class="d-flex justify-content-end text-right wow fadeIn" :class="isMobile ? 'mt-4' : ''" data-wow-delay=".1s">
					<button class="btn-menu navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
						Menu
						<img :src="menuClose" class="ml-2"/>
					</button>
				</div>
				
                <div class="collapse navbar-collapse height" id="navbarToggleExternalContent">
					<button class="navbar-toggler fixed" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
					<img :src="menuOpen" class="mr-2"/>
						Fechar
					</button>
					<menu-global-multinivel :items="items" :is-mobile="isMobile"/>
				</div>

				<div class="py-3 px-5 w-100 align-items-center" id="fixedMenu" :class="isMobile ? 'justify-content-between' : ''">
					<a href="/" title="Ir para Home">
                        <img src="/style library/images/logo-rodape.png" alt="Pellegrina & Monteiro Advogados" class="logo-footer" :class="isMobile ? '' : 'mr-5'">
                    </a>
					<menu-global-multinivel :items="items" :is-mobile="isMobile" :is-menu-mobile="isMobile ? true : false" :is-fixed="true"/>
				</div>

            </nav>
        </div>`,
  });

  new Vue({
    el: "#menu-component",
    template: "<menu-component/>",
  });
}
menuGlobalScope(Vue, $pnp);
