$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

Vue.component(`redes-socias-footer`, {
  mixins: [translateMixin],
  template: `
    <div class="redes-socias-footer row mt-3 p-0 pl-3" :class="getClass()">
        <div class="col-sm-2 col-2 text-center icon p-0" v-for="item in items">
            <a :href="'mailto:' + item.URL" target="_blank" :title="isEnglish ? 'visit ' + item.Title : 'ir para ' + item.Title" class="link-icons" v-if="item.Title == 'E-mail'">
                <i :class="item.Icone"></i>
            </a>
            <a :href="item.URL" target="_blank" :title="isEnglish ? 'visit ' + item.Title : 'ir para ' + item.Title" class="link-icons" v-else>
                <i :class="item.Icone"></i>
            </a>
        </div>
    </div>
    `,
  data() {
    var listName = `RedesSociais`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      isMobile: window.innerWidth < 767 ? true : false,
      isTablet:
        window.innerWidth >= 768 && window.innerWidth <= 1024 ? true : false,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.filter(`Ocultar eq 'Não'`)
        .orderBy(`Ordem`, true)
        .top(100)
        .get()
        .then((results) => {
          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`,
          );
        });
    },
    getClass() {
      if (this.isMobile) {
        return "justify-content-center";
      }
      if (this.isTablet) {
        return "justify-content-between";
      }
      if (!this.isTablet && !this.isMobile) {
        return "justify-content-start";
      }
    },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});

var app = new Vue({
  el: "#redes-socias-footer",
  template: `<redes-socias-footer />`,
});

Vue.component(`unidades-footer`, {
  mixins: [translateMixin],
  template: `
    <div class="unidades-footer ml-7 row" :class="isMobile ? 'pr-3 text-center' : ''">
        <div class="col-sm-5 mt-5" v-for="item in items" :class="isMobile ? 'px-4 mb-4' : ''">
            <h2 class="title-unidade">
                <i class="fas fa-map-marker-alt"></i> 
                {{ isEnglish ? item.TitleEN : item.Title }} 
                <span class="sigla">{{ item.Sigla }}</span>
            </h2>
            <h4 v-show="item.Telefone">{{ isEnglish ? item.TelefoneEN : item.Telefone }}</h4>
            <div v-html="isEnglish ? item.DescricaoEN : item.Descricao" class="description"></div>
            <a :href="item.Maps" target="_blank" class="map" :title="isEnglish ? 'see the map' : 'veja o mapa' ">[{{ isEnglish ? 'see the map' : 'veja o mapa' }}]</a>
        </div>
    </div>
    `,
  data() {
    var listName = `Unidades`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      isMobile: window.innerWidth < 996 ? true : false,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.orderBy(`Ordem`, true)
        .top(100)
        .get()
        .then((results) => {
          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`,
          );
        });
    },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});

var app = new Vue({
  el: "#unidades-footer",
  template: `<unidades-footer />`,
});
