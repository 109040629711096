$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

Vue.component(`loading`, {
  data() {
    return {
      isLoaded: false,
    };
  },
  template: `
    <div id="loading-page" class="bg-white position-fixed" :class="{'loaded': isLoaded}">
        <h2 class="title">Pellegrina & Monteiro Advogados</h2>
        <div class="loader"></div>
    </div>
    `,
  methods: {
    loadingPage() {
      $(`body`).addClass(`loading`);
      setTimeout(() => {
        this.isLoaded = true;
        $(`#loading-page`).animate(
          {
            opacity: "0",
          },
          100,
        );
        $(`#loading-page`).remove();
        $(`#experiencias`).removeClass('d-none');
        $(`body`).removeClass(`loading`);
      }, 3000);
    },
    getImage() {
      return `${_spPageContextInfo.webServerRelativeUrl}Style%20Library/Images/loading.gif`;
    },
  },
  created() {
    this.loadingPage();
  },
});

var app = new Vue({
  el: `#loading`,
  template: `<loading />`,
});
