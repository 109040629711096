////////////////////////////////////////////
//  MASTER - UNIFICANDO COMPONENTS E JS
////////////////////////////////////////////

new WOW().init();

require("../home/loading-page"); // sections
require("../../mixins/translate"); // translate mixin
require("../../mixins/pnp-mixin"); // pnp mixin (utilizado no menu-global);
require("../menus/menu-topo"); // menu topo
require("../menus/menu-global"); // menu global
require("./footer"); // footer

////////////////////////////////////////////
//  MASTER - SCRIPTS
////////////////////////////////////////////

$(document).ready(function () {
  //pegando o idioma e modificando exibição
  if (CurrentLanguageLCID == "pt-BR") {
    $('[lang="pt-br"]').show();
    $('[lang="en-us"]').hide();
  } else {
    $('[lang="pt-br"]').hide();
    $('[lang="en-us"]').show();
  }
  if (window.location.href.indexOf("default.aspx") > -1) {
    $("header .navbar-collapse.show").css("right", "8em");
  }
});

// //verificando se está no acesso anonimo para remover #s4-workspace
// if(!_spPageContextInfo.userId){
//     $(`#s4-workspace`).removeAttr('id')
// }

// // verificando se está na home para adicionar classe que customiza o header apenas na home
// if(_spPageContextInfo.pageItemId == 2){
//     $(`header`).addClass('home');
//     var ua = window.navigator.userAgent;
//     var msie = ua.indexOf("MSIE ");

//     if(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
//         $(`header`).addClass(`ie`)
//     }
// }
