$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

require("./menu-idiomas");

Vue.component(`menu-topo`, {
  mixins: [translateMixin],
  template: `
    <div class="menu-topo pt-3 pb-3 bg-white wow fadeInUp" data-wow-delay="1s" v-if="isLoaded">
        <div class="container-fluid p-0">
            <div class="d-flex align-items-center justify-content-end" :class="isMobile ? 'flex-column' : ''">
                <div v-if="false">
                    <i class="fas fa-map-marker-alt"></i>
                    <span>São Paulo, {{ isEnglish ? 'Brazil' : 'Brasil' }}</span>
                </div>
                <ul class="nav" :class="isMobile ? 'd-flex' : ''">
                    <li class="nav-item d-flex" v-for="item in items">
                         <span class="mr-3 ml-3 pipe"> | </span> 
                        <a :href="item.URL" :title=" isEnglish ? 'visit ' + item.TitleEN : 'ir para ' + item.Title">
                            {{ isEnglish ? item.TitleEN : item.Title }} 
                        </a> 
                       
                    </li>

                    <li class="nav-item ml-3">
                        <menu-idiomas></menu-idiomas>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    `,
  data() {
    var listName = `Destaques`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      isMobile: window.innerWidth < 996 ? true : false,
      isLoaded: false,
    };
  },
  created() {
    this.getItems().then(() => (this.isLoaded = true));
  },
  methods: {
    getItems() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.orderBy(`Ordem`, true)
        .top(100)
        .get()
        .then((results) => {
          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`,
          );
        });
    },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});

var app = new Vue({
  el: "#menu-topo",
  template: `<menu-topo />`,
});
