$pnp.setup({
    sp: {
        headers: {
            "Accept": "application/json; odata=verbose"
        }
    }
});
Vue.component('menu-idiomas',{
    mixins: [translateMixin],
    template:`
        <element class="idiomas" v-if="false">
            <div class="translate text-sm-right d-sm-none d-md-block justify-content-ends">
                <a v-for="item in idiomas" href="javascript:" @click="changeLanguage(item.LCID)"  :title="isEnglish ? item.AltEN : item.Alt" class="ml-2">
                    <img :src="item.Flag" :alt="isEnglish ? item.AltEN : item.Alt" class="flag"/>
                </a>
            </div>
        </element>
    `,
    data: function() {
        return {
            idiomas: [
                {
                    Alt:'Português',
                    AltEN: 'Portuguese',
                    Title: 'PT',
                    LCID: '1046',
                    Flag: `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/br.png`
                },
                /*{
                    Alt: 'Inglês',
                    AltEN: 'English',
                    Title: 'EN',
                    LCID: '1033',
                    Flag: `${_spPageContextInfo.siteAbsoluteUrl}/style library/images/en.png`
                }*/
            ]
        }
    }
})